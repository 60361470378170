<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <v-btn depressed color="primary" @click="createNew">{{ $t("users.new") }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog v-model="dialog" max-width="700px" persistent>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("users.edit_title") }}</span>
              <v-spacer></v-spacer>
              <v-switch

                v-model="editItem.is_deleted"
                :label="$t('common.deleted')"
              ></v-switch>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >

                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editItem.username"
                                    :label="$t('users.login')"
                                    :rules="[() => !!editItem.username || $t('common.required')]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editItem.name"
                                    :label="$t('common.name')"
                                    :rules="[() => !!editItem.name || $t('common.required')]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editItem.password"
                                    :label="$t('users.password')"
                                    :hint="$t('users.if_change')"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="editItem.roles"
                        :items="roles"
                        :menu-props="{ maxHeight: '400' }"
                        :label="$t('users.roles')"
                        chips
                        multiple
                        :hint="$t('users.select_roles')"
                        persistent-hint
                        item-text="title"
                        item-value="id"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @click="toggleRoles"
                          >
                            <v-list-item-action>
                              <v-icon
                                :color="editItem.roles.length > 0 ? 'indigo darken-4' : ''">
                                {{ editSelectAllicon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t("common.select_all") }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </v-col>

                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">{{ $t("common.cancel") }}</v-btn>
              <v-btn color="blue darken-1" text @click="saveUser">{{ $t("common.save") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-data-table item-key="_id" class="elevation-1"
                      :loading-text="$t('common.loading_please_wait')"
                      :headers="headers"
                      :items="users"
                      :options.sync="options"
                      :loading="loading"
                      hide-default-footer>
          <template v-slot:item.roles="{ item }">
            <v-chip x-small v-for="role in item.roles" v-if="rolesMap[role] != undefined">
              {{ rolesMap[role].title }}
            </v-chip>
          </template>
          <template v-slot:item.is_deleted="{ item }">
            <i v-if="item.is_deleted" class="fas fa-trash"></i>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItemAction(item)">
              edit
            </v-icon>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="options.page" :length="totalPages"></v-pagination>
        </div>

      </v-col>
    </v-row>
  </v-container>
</template>


<script>

export default {
  name: "UsersList",
  data() {
    return {
      valid: true,
      users: [],
      totalPages: 1,
      roles: [],
      rolesMap: {},
      options: {
        page: 1
      },
      dialog: false,
      loading: true,
      headers: [
        {
          text: '',
          sortable: false,
          width: '50px',
          value: 'is_deleted',
        },
        {
          text: this.$t('users.login'),
          sortable: false,
          value: 'username',
        },
        {
          text: this.$t('common.name'),
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('users.roles'),
          sortable: false,
          value: 'roles',
          width: '500px'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      editItem: {},
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    }
  },
  methods: {
    toggleRoles() {
      this.$nextTick(() => {
        if (this.editItem.roles.length == this.roles.length) {
          this.editItem.roles = []
        } else {
          this.editItem.roles = this.roles.map(function (item) {
            return item.id;
          });
        }
      })
    },
    createNew() {
      this.editItem = {
        _id: null,
        username: '',
        name: '',
        password: '',
        is_deleted: false,
        roles: [],
      }
      this.dialog = true
    },
    editItemAction(item) {
      this.editItem = Object.assign({}, item);
      this.dialog = true
    },
    async saveUser() {
      if (!this.$refs.form.validate()) {
        return;
      }

      await this.$api.users.saveUser(this.editItem)
      this.dialog = false
      await this.getDataFromApi()
      this.$refs.form.reset()
    },
    async getDataFromApi() {
      this.loading = true
      // const {sortBy, sortDesc, page, itemsPerPage} = this.options
      const response = await this.$api.users.getUsers(this.options)
      this.users = response.items
      this.totalPages = response.total_pages
      this.loading = false
    },
  },
  computed: {
    editSelectAllicon() {
      if (this.editItem.roles.length == this.roles.length) return 'fas fa-check-square'
      if (this.editItem.roles.length > 0) return 'fas fa-minus-square'
      return 'far fa-square'
    },
  },
  async mounted() {
    this.$store.commit('app/setLoading', true)
    const roles = await this.$api.users.getRoles()
    this.roles = roles;
    for (let role of roles) {
      this.rolesMap[role.id] = role;
    }

    await this.getDataFromApi()
    this.$store.commit('app/setLoading', false)
  },
}
</script>

<style scoped>

</style>
